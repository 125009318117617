import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import styles from './unit-upgrade-styles.scss';
import checkoutStyles from '../styles.scss';
import Translation from '../../../../../components/stateless/Translation';
import {
  EASIER_PRICING_TYPE_ID,
  EASIEST_PRICING_TYPE_ID,
} from '../constants';
import {
  TRACK_CLICK_CHECKOUT_UPGRADE_EASIER,
  TRACK_CLICK_CHECKOUT_UPGRADE_EASIEST,
} from '../../../../../../src/web/utils/dataLayer';
import Price from '../../../../../components/stateless/Price';
import unitHighlight1 from './unit-highlight-1.svg';
import unitHighlight2 from './unit-highlight-2.svg';
import unitHighlight3 from './unit-highlight-3.svg';

const UnitUpgradeOptions = ({
  easierPricingType,
  setEasierPricingType,
  easiestPricingType,
  setEasiestPricingType,
  currency,
  currencyLocale,
  unitPrice,
  unitGroup,
  firstMonthFree,
  tierId,
  useWeeklyRates,
}) => {
  const renderFirstMonthFree = () => (
    firstMonthFree && (
      <div className="font-bold mb-1 text-xs leading-3">
        <Translation
          id="widgets.checkout.firstMonthFree"
          defaultMessage="First month free"
        />
      </div>
    )
  );

  const renderUpgradeUnitFeature = translation => (
    <li className={styles.upgradeUnitFeature}>
      <div className="bg-[#206F70] text-white inline-block text-sm font-bold py-2 px-6 w-[70%]">
        <Translation
          {...translation}
        />
      </div>
      <div className={styles.triangle} />
    </li>
  );

  const renderUnitUpgradePriceDifference = (upgradeOption, basePrice) => {
    const upgradePrice = (
      upgradeOption.marketRate
      || upgradeOption.discountedPrice
      || upgradeOption.price
    );
    const upgradeWeeklyPrice = upgradeOption.marketRateWeekly || upgradePrice;

    return (
      <div className="text-xs text-black leading-3">
        <Price
          currency={currency}
          locale={currencyLocale}
          value={(
            (useWeeklyRates
              ? upgradeWeeklyPrice
              : upgradePrice)
            - basePrice
          ).toFixed(2)
          }
        >
          {formattedPrice => (
            <Translation
              id="widgets.checkout.upgradeOptionCost"
              defaultMessage="Then {cost}"
              values={{
                cost: <>{`${formattedPrice}`}</>,
              }}
            />
          )}
        </Price>
        {useWeeklyRates
          ? <Translation id="widgets.abbreviation.wk" defaultMessage="/wk" />
          : <Translation id="widgets.abbreviation.mo" defaultMessage="/mo" />
        }
      </div>
    );
  };

  return (
    <div className={classNames(styles.unitUpgradeOptions, styles.unitUpgradeOptionsB)}>
      <div className={checkoutStyles.divider} />
      <h3>
        <Translation
          id="widgets.checkout.upgradeUnitTitle"
          defaultMessage="Upgrade to make your move easier"
        />
      </h3>
      <p className="mb-6">
        <Translation
          id="widgets.checkout.upgradeUnitSubtitle"
          defaultMessage="Take your pick of these free upgrades:"
        />
      </p>
      <div className="mb-4 flex gap-4 items-center flex-row">
        <div className="flex gap-4 items-center flex-col">
          <Translation
            id="widgets.checkout.upgradeUnitHighlight1"
            defaultMessage="Nearby Entry"
          >
            {text => (
              <>
                <img
                  src={unitHighlight1}
                  alt={text}
                  type="image/svg+xml"
                  className="w-4/5 mb-2"
                  loading="lazy"
                />
                <div>
                  {text}
                </div>
              </>
            )}
          </Translation>
        </div>
        <div className="flex gap-4 items-center flex-col">
          <Translation
            id="widgets.checkout.upgradeUnitHighlight2"
            defaultMessage="Get Done Faster"
          >
            {text => (
              <>
                <img
                  src={unitHighlight2}
                  alt={text}
                  type="image/svg+xml"
                  className="w-4/5 mb-2"
                  loading="lazy"
                />
                <div>
                  {text}
                </div>
              </>
            )}
          </Translation>
        </div>
        <div className="flex gap-4 items-center flex-col">
          <Translation
            id="widgets.checkout.upgradeUnitHighlight3"
            defaultMessage="Less Effort"
          >
            {text => (
              <>
                <img
                  src={unitHighlight3}
                  alt={text}
                  type="image/svg+xml"
                  className="w-4/5 mb-2"
                  loading="lazy"
                />
                <div>
                  {text}
                </div>
              </>
            )}
          </Translation>
        </div>
      </div>
      <Row bsClass={classNames(styles.upgradeOptions, 'row lg:flex')}>
        <Col xs={12} md={6} bsClass={classNames(styles.upgradeOptionContainer, 'col')}>
          <div
            className={classNames(styles.upgradeOption, 'rounded-xl h-full flex flex-col mb-4 relative', {
              [styles.selected]: tierId === EASIER_PRICING_TYPE_ID,
              'opacity-50 cursor-default': (tierId !== EASIER_PRICING_TYPE_ID
                && !easierPricingType
              ),
            })}
            onClick={setEasierPricingType}
            onKeyPress={setEasierPricingType}
            role="button"
            tabIndex="0"
            {...TRACK_CLICK_CHECKOUT_UPGRADE_EASIER}
          >
            <div className={`${styles.upgradeOptionInfo} bg-[#FFF6E0] rounded-xl p-4 flex-grow`}>
              <div className="flex gap-4 align-center justify-between">
                <div>
                  <h6 className={styles.upgradeOptionTitle}>
                    <Translation
                      id="widgets.checkout.upgradeEasierTitle"
                      defaultMessage="Easier"
                    />
                  </h6>
                  {renderFirstMonthFree()}
                  {
                    easierPricingType
                      || tierId === EASIER_PRICING_TYPE_ID
                      ? (
                        renderUnitUpgradePriceDifference(
                          easierPricingType || unitGroup,
                          unitPrice,
                        )
                      )
                      : <div className="text-xs text-black leading-3">&nbsp;</div>
                  }
                </div>
                <div>
                  <div className={`${styles.selectButton} rounded-md text-center uppercase font-bold text-base p-1.5`}>
                    <div className={`${styles.selectionCheck} h-6 w-6 bg-white rounded-full border-2 border-black float-left mr-1`} />
                    <Translation
                      id="widgets.checkout.selectButton"
                      defaultMessage="Select"
                    />
                  </div>
                </div>
              </div>
              <ul className={`${styles.upgradeUnitFeatures} list-none m-0 p-0 text-left mt-4`}>
                {renderUpgradeUnitFeature({
                  id: 'widgets.checkout.closeToEntry',
                  defaultMessage: 'Close to entry',
                })}
                {renderUpgradeUnitFeature({
                  id: 'widgets.checkout.lessManualLabor',
                  defaultMessage: 'Less manual labor',
                })}
              </ul>
            </div>
            {
              !easierPricingType
              && tierId !== EASIER_PRICING_TYPE_ID
              && (
                <div className={styles.upgradeOptionSoldOut}>
                  <p>
                    <Translation
                      id="widgets.checkout.unitUpgradeSoldOut"
                      defaultMessage="This option is currently sold out"
                    />
                  </p>
                </div>
              )
            }
          </div>
        </Col>
        <Col xs={12} md={6} bsClass={classNames(styles.upgradeOptionContainer, 'col')}>
          <div
            className={classNames(styles.upgradeOption, 'rounded-xl h-full flex flex-col mb-4 relative', {
              [styles.selected]: tierId === EASIEST_PRICING_TYPE_ID,
              'opacity-50 cursor-default': (tierId !== EASIEST_PRICING_TYPE_ID
                && !easiestPricingType
              ),
            })}
            onClick={setEasiestPricingType}
            onKeyPress={setEasiestPricingType}
            role="button"
            tabIndex="0"
            {...TRACK_CLICK_CHECKOUT_UPGRADE_EASIEST}
          >
            <div className={`${styles.upgradeOptionInfo} bg-[#FFF6E0] rounded-xl p-4 flex-grow`}>
              <div className="flex gap-4 align-center justify-between">
                <div>
                  <h6 className={styles.upgradeOptionTitle}>
                    <Translation
                      id="widgets.checkout.upgradeEasiestTitle"
                      defaultMessage="Easiest"
                    />
                  </h6>
                  {renderFirstMonthFree()}
                  {
                    easiestPricingType
                      ? (
                        renderUnitUpgradePriceDifference(
                          easiestPricingType || unitGroup,
                          unitPrice,
                        )
                      )
                      : <div className="text-xs text-black leading-3">&nbsp;</div>
                  }
                </div>
                <div>
                  <div className={`${styles.selectButton} rounded-md text-center uppercase font-bold text-base p-1.5`}>
                    <div className={`${styles.selectionCheck} h-6 w-6 bg-white rounded-full border-2 border-black float-left mr-1`} />
                    <Translation
                      id="widgets.checkout.selectButton"
                      defaultMessage="Select"
                    />
                  </div>
                </div>
              </div>
              <ul className={`${styles.upgradeUnitFeatures} list-none m-0 p-0 text-left mt-4`}>
                {renderUpgradeUnitFeature({
                  id: 'widgets.checkout.closestToEntry',
                  defaultMessage: 'Closest to entry',
                })}
                {renderUpgradeUnitFeature({
                  id: 'widgets.checkout.easiestAccess',
                  defaultMessage: 'Easiest access',
                })}
                {renderUpgradeUnitFeature({
                  id: 'widgets.checkout.nearestToOffice',
                  defaultMessage: 'Nearest to the office',
                })}
                {renderUpgradeUnitFeature({
                  id: 'widgets.checkout.leastManualLabor',
                  defaultMessage: 'Least manual labor',
                })}
              </ul>
            </div>
            {
              !easiestPricingType
              && tierId !== EASIEST_PRICING_TYPE_ID
              && (
                <div className="rounded-md text-center bg-[#FFF6E0]">
                  <p>
                    <Translation
                      id="widgets.checkout.unitUpgradeSoldOut"
                      defaultMessage="This option is currently sold out"
                    />
                  </p>
                </div>
              )
            }
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UnitUpgradeOptions;
