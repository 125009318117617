import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { object, string } from 'yup';
import { Row, Col, Alert } from 'react-bootstrap';
import get from 'lodash/get';
import SMFormFieldToggle from '../../../../components/stateless/SMFormFieldToggle';
import withForm from '../../../../../../admin/hocs/withForm';
import Translation from '../../../../../components/stateless/Translation';
import CheckoutSubmitButton from '../CheckoutSubmitButton';
import CheckoutFormFieldText from '../CheckoutFormFieldText';
import CheckoutFormFieldPhone from '../CheckoutFormFieldPhone';
import CheckoutFormStateDropdown from '../CheckoutFormStateDropdown';
import checkoutStyles from '../styles.scss';
import {
  TRACK_CLICK_CHECKOUT_RESERVATION_STEP_ONE_SUBMIT,
  TRACK_CLICK_CHECKOUT_STEP_ONE_SUBMIT,
  TRACK_CHECKOUT_STEP,
} from '../../../../../../src/web/utils/dataLayer';
import { GB } from '../../../../../../src/shared/i18n';

const propTypes = {
  formError: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isReservation: PropTypes.bool.isRequired,
  facility: PropTypes.shape().isRequired,
};

const defaultProps = {};

const validationSchema = object().shape({
  firstName: string()
    .required('widgets.checkout.firstName.required'),
  lastName: string()
    .required('widgets.checkout.lastName.required'),
  phone: object().shape({
    unformattedPhoneNumber: string()
      .matches(/^[0-9|(|)|\-|\s|+]*$/)
      .required('widgets.checkout.phone.required'),
  }),
  email: string()
    .required('widgets.checkout.email.required')
    .email('widgets.checkout.email.invalid'),
  address1: string()
    .when('isReservation', {
      is: false,
      then: string().required('widgets.checkout.address1.required'),
    }),
  city: string()
    .when('isReservation', {
      is: false,
      then: string().required('widgets.checkout.city.required'),
    }),
  state: string()
    .when(['isReservation', 'isFacilityGB'], {
      is: false,
      then: string().required('widgets.checkout.state.required'),
    }),
  postal: string()
    .when('isReservation', {
      is: false,
      then: string().required('widgets.checkout.postal.required'),
    }),
});

const initialValues = {
  firstName: '',
  lastName: '',
  phone: {
    country: null,
    phoneNumber: '',
    unformattedPhoneNumber: '',
  },
  email: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postal: '',
  smsOptIn: false,
  isReservation: false,
  isFacilityGB: false,
};

const ContactInformationForm = ({
  handleSubmit,
  submitting,
  formError,
  isReservation,
  handleChange,
  facility,
}) => {
  const [hasTrackedDataLayer, setHasTrackedDataLayer] = useState(false);

  useEffect(() => {
    if (!hasTrackedDataLayer && facility) {
      setHasTrackedDataLayer(true);
      TRACK_CHECKOUT_STEP(get(facility, 'unitGroup'), facility, 'new_booking_flow_step1', 'contact info');
    }
  }, [facility, hasTrackedDataLayer]);

  return (
    <form
      onSubmit={handleSubmit}
    >
      {
        formError && (
          <Alert bsStyle="danger">
            <Translation
              id="widgets.checkout.formError"
              defaultMessage="An error was encountered when submitting the form. Please try again."
            />
          </Alert>
        )
      }
      <Row>
        <Col xs={12} md={8}>
          <CheckoutFormFieldText
            required
            name="firstName"
            label={{
              defaultMessage: 'First Name',
              id: 'widgets.checkout.firstName',
            }}
          />
          <CheckoutFormFieldText
            required
            name="lastName"
            label={{
              defaultMessage: 'Last Name',
              id: 'widgets.checkout.lastName',
            }}
          />
          <CheckoutFormFieldText
            required
            name="email"
            label={{
              defaultMessage: 'Email Address',
              id: 'widgets.checkout.email',
            }}
          />
          <CheckoutFormFieldPhone
            required
            name="phone"
            label={{
              defaultMessage: 'Phone Number',
              id: 'widgets.checkout.phone',
            }}
            help={{
              id: 'widgets.checkout.phone.help',
              defaultMessage: 'We will only call about your order',
            }}
          />
          <SMFormFieldToggle
            type="checkbox"
            name="smsOptIn"
            value
            label={(
              <Translation
                id="widgets.checkout.smsOptIn"
                defaultMessage="Text me reminders and notifications about my storage unit"
              />
            )}
          />
        </Col>
      </Row>
      { !isReservation && (
        <>
          <div className={checkoutStyles.divider} />
          <Row>
            <Col xs={12} md={8}>
              <h3>
                <Translation
                  id="widgets.checkout.mailingAddressTitle"
                  defaultMessage="Mailing Address"
                />
              </h3>
              <CheckoutFormFieldText
                required
                name="address1"
                label={{
                  defaultMessage: 'Address',
                  id: 'widgets.checkout.address1',
                }}
              />
              <CheckoutFormFieldText
                name="address2"
                label={{
                  defaultMessage: 'Bldg/Apt/Suite (Optional)',
                  id: 'widgets.checkout.address2',
                }}
              />
              <CheckoutFormFieldText
                required
                name="city"
                label={{
                  defaultMessage: 'City',
                  id: 'widgets.checkout.city',
                }}
              />
            </Col>
          </Row>
          <Row>
            {get(facility, 'address.country') !== GB ? (
              <Col xs={12} md={4}>
                <CheckoutFormStateDropdown
                  label={{
                    defaultMessage: 'State',
                    id: 'widgets.checkout.state',
                  }}
                  required
                  name="state"
                  country={get(facility, 'address.country')}
                  onChange={handleChange}
                  emptyOption={{
                    defaultMessage: 'Select State',
                    id: 'widgets.checkout.stateDefault',
                  }}
                />
              </Col>
            )
              : null}
            <Col xs={12} md={4}>
              <CheckoutFormFieldText
                required
                name="postal"
                label={{
                  defaultMessage: 'Zip Code',
                  id: 'widgets.checkout.postal',
                }}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col xs={12} md={4}>
          <CheckoutSubmitButton
            submitLabel={{
              id: 'widgets.checkout.saveButton.submit',
              defaultMessage: 'Save and Continue',
            }}
            submitting={submitting}
            submittingLabel={{
              id: 'widgets.checkout.saveButton.submitting',
              defaultMessage: 'Saving...',
            }}
            {...(isReservation
              ? TRACK_CLICK_CHECKOUT_RESERVATION_STEP_ONE_SUBMIT
              : TRACK_CLICK_CHECKOUT_STEP_ONE_SUBMIT)
            }
          />
        </Col>
      </Row>
    </form>
  );
};

ContactInformationForm.propTypes = propTypes;
ContactInformationForm.defaultProps = defaultProps;

export default withForm({
  mapPropsToValues: (props) => {
    const formattedInitialValues = initialValues;

    if (props.formData) {
      formattedInitialValues.firstName = props.formData.tenant.firstName;
      formattedInitialValues.lastName = props.formData.tenant.lastName;
      formattedInitialValues.phone = {
        country: null,
        phoneNumber: props.formData.tenant.phone,
        unformattedPhoneNumber: props.formData.tenant.phone,
      };
      formattedInitialValues.email = props.formData.tenant.email;
      formattedInitialValues.address1 = props.formData.tenant.address1;
      formattedInitialValues.address2 = props.formData.tenant.address2;
      formattedInitialValues.city = props.formData.tenant.city;
      formattedInitialValues.state = props.formData.tenant.state;
      formattedInitialValues.postal = props.formData.tenant.postal;
      formattedInitialValues.smsOptIn = props.formData.tenant.smsOptIn;
    }
    formattedInitialValues.isReservation = props.isReservation;
    formattedInitialValues.isFacilityGB = get(props, 'facility.address.country') === GB;

    return formattedInitialValues;
  },
  validationSchema,
  handleSubmit: (formPayload, formikBag) => (
    formikBag.props.onSubmit(
      formPayload,
      formikBag.props.currentStep,
      formikBag.props.nextStep,
      formikBag.props.scrollToRef,
    )
  ),
})(ContactInformationForm);
